'use client';

import { FC } from 'react';
import ZpButton from 'components/UI/Button/ZpButton';

interface IError {
  error: Error;
  reset: () => void;
}

const Error: FC<IError> = ({ error, reset }) => {
  return (
    <div className='flex items-center justify-center flex-col space-y-5 absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 text-center w-full'>
      <h2> مشکلی رخ داده است دوباره تلاش کنید</h2>
      <h3>Message : {error.message || 'خطا'}</h3>

      <ZpButton onClick={reset}>دوباره تلاش کن</ZpButton>
    </div>
  );
};

export default Error;
